import React, { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion"
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import AppContext from 'contexts/AppContext';
import Button from 'components/Button/Button'
import CursorElement from 'components/CursorElement/CursorElement'
import cn from 'classnames';
import "./Home.scss";
import useMouse from '@react-hook/mouse-position'
//IMAGES
import Jofre from 'images/jofre.jpg';
import Mobilio from 'images/mobilio.jpg';
import Karen from 'images/karen.jpg';

function Home() {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  //scroll
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {

    setCurrentSection("home");

    if(scroll){
      scroll.destroy();
      scroll.init();
    }
  }, [scroll]);
  //Handle advanced page transitions
  const pageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0 , transition:{ duration: .5}}
  }
  const pageAnimationStart = e => {
  };
  const pageAnimationComplete = e => {
  };
  //
  //MOUSE HOOKS
  const hoverRef = React.useRef(null)
  const mouse = useMouse(hoverRef, {
     enterDelay: 100,
     leaveDelay: 100,
  })

  const [elementActive, setElementActive]= useState("")
  function cursorElementActive(e) {
    setElementActive(e)
  }
  function cursorElementInactive() {
    setElementActive("")
  }

  return (
  <motion.div
    exit="exit"
    animate="visible"
    initial="hidden"
    variants={pageVariants}
    onAnimationStart={pageAnimationStart}
    onAnimationComplete={pageAnimationComplete}
  >
    <div className={  cn({
            ["page"]: true,
            ["home"]: true
          })}
    >
      <section className="Hero" ref={hoverRef}>
        <h1>Entrevista a dos femicidas.<br/>
          Acompaña a Celeste y Daiana entrevistando cara a cara a los femicidas {" "}
          <span className="highlight" onMouseOver={(e) => cursorElementActive("Jofre")}  onMouseOut={() => cursorElementInactive()}>Jofré</span>
          {" "} y {" "} <span className="highlight" onMouseOver={(e) => cursorElementActive("Mobilio")}  onMouseOut={() => cursorElementInactive()}>Mobilio</span>,
            asesinos de <span className="highlight" onMouseOver={(e) => cursorElementActive("Karen")}  onMouseOut={() => cursorElementInactive()}>Karen Alvarez</span>, el 24 de octubre de 2014 en Viedma, Rio Negro.</h1>
        <div className="Hero__actions">
          <Button link="/jofre">Empezar</Button>
          <Button link="/menu" whatsapp>Ver en whatsapp</Button>
        </div>
        <CursorElement active={elementActive==="Jofre"} x={mouse.x} y={mouse.y}><img src={Jofre} alt="Jofré"/></CursorElement>
        <CursorElement active={elementActive==="Mobilio"} x={mouse.x} y={mouse.y}><img src={Mobilio} alt="Mobilio"/></CursorElement>
        <CursorElement active={elementActive==="Karen"} x={mouse.x} y={mouse.y}><img src={Karen} alt="Karen"/></CursorElement>
      </section>
      <section className="ProjectInfo">
        <p className="p p-s">Este es un trabajo fotografico-documental sobre femicidas en Argentina que comenzamos en 2017 y aún está en proceso. Al plantearnos trabajar sobre este tema decidimos mirar lo incómodo, poner el foco en el femicida, y en el entramado patriarcal en el que vivimos donde anida la violencia machista y que en su grado mayor llega a matar a una mujer por su condición de tal.  Indagar en sus historias, correr las telas superficiales de cada caso, habilitar preguntas para romper con construcciones de análisis pre establecidas: quién es este hombre? ¿este tipo que por la mañana trabaja al lado mío/tuyo en la oficina es capaz de eso? cuál es el entramado social donde ocurre el femicidio? donde anida el patriarcado en estos hechos de violencia? todos los hombres son probables femicidas? </p>
      </section>
    </div>
  </motion.div>
  )
}

export default Home;
