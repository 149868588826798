import "./Button.scss";
import React from "react";
import cn from 'classnames';
import { NavLink} from 'react-router-dom'


function Button({children, color, link, inline, clickHandler, small, whatsapp}) {

    var buttonElement;
    if(link){
      buttonElement = <NavLink to={link}>  {children} </NavLink>
    }else {
      if(clickHandler){
        buttonElement = <div className="__container" onClick={clickHandler}>  {children} </div>
      }else {
        buttonElement = <div className="__container">  {children} </div>
      }
    }

    return (
      <div className={  cn({
          ["Button"]: true,
          ["--wa"]: whatsapp,
          ["--inline"]: inline,
          ["--small"]: small,

        })}>
            {buttonElement}
      </div>
    );

}
export default Button;
