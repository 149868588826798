import "./FixedContent.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import Button from 'components/Button/Button'
import AppContext from 'contexts/AppContext';
import ChatContext from 'contexts/ChatContext';
import Element from 'components/Element/Element';
import ContentImage from 'components/ContentImage/ContentImage';
import cn from 'classnames';

function FixedContent({scrollTarget, currentContent }) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);

  const [items, setItems] = useState();
  const [currentItem, setCurrentItem] = useState();

  useEffect(()=>{
    if(currentContent){
      console.log("currentContent",currentContent);
      if(items){
        const matches = items.find(element => element === currentContent);
        console.log("el item ya estaba?? ",matches);
        if(!matches){
          setItems(oldItems => [...oldItems, currentContent])
          setCurrentItem(items.length);
        }else {
          setCurrentItem(items.indexOf(currentContent));
        }

      }else {
        setItems([currentContent])
        setCurrentItem(0);
      }
    }

  }, [currentContent])

  useEffect(()=>{
    console.log("items",items);
  }, [items])


    return (
      <div className={  cn({
          ["FixedContent"]: true,
        })}
        data-scroll data-scroll-sticky data-scroll-target={scrollTarget}

        >




        {items && items.map((element, index) => (
          <ContentImage key={index} index={index} current={currentItem} image={element} />
        ))}

      </div>
    );

}
export default FixedContent;
