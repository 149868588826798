import "./ContentImage.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import AppContext from 'contexts/AppContext';
import TurnContext from 'contexts/TurnContext';
import Element from 'components/Element/Element';
import cn from 'classnames';
import gsap from 'gsap';

function ContentImage({ index, current, image}) {
  const [rotation, setRotation] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const elementRef = useRef();

  useEffect(() => {

    if(index === current){
     setRotation(Math.floor( Math.random()*20 -  Math.random()*20));
     setPositionX(Math.floor( 0 -  Math.random()*160));
     setPositionY(Math.floor( Math.random()*80 -  Math.random()*40));
    }
  },[current]);

  useEffect(() => {

    if(rotation !== 0){
     gsap.to(elementRef.current, { ease: "expo.out", duration: 2, transform: `translate(${positionX}px, ${positionY}px) rotate(${rotation}deg)`   });
    }
  },[rotation, positionX, positionY]);


    return (
      <div className={  cn({
          ["ContentImage"]: true,
          ["--active"]: index === current,

          })}
          ref={elementRef}
        >

           <Element customClass="ContentImage__Element" >
             <img src={image} alt={image}/>
           </Element>


      </div>
    );

}
export default ContentImage;
