import "./Nav.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import Button from 'components/Button/Button'
import AppContext from 'contexts/AppContext';
import cn from 'classnames';

function Nav() {
  const {  currentSection, setCurrentSection } = useContext(AppContext);




    return (
      <div className={  cn({
          ["Nav"]: true,
          ["--dark"]: currentSection === "jofre"
        })}>

        <span>
          <Button link="/menu"><span className="MenuButton"></span></Button>

        </span>
      </div>
    );

}
export default Nav;
