import "./CursorElement.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import cn from 'classnames';
import gsap from 'gsap';

function CursorElement({x, y, children, active}) {

    const [rotation, setRotation] = useState(0);

    const elementRef = useRef();


    useEffect(() => {
       gsap.to(elementRef.current, { ease: "expo.out", duration: 2, transform: `translate(${x}px, ${y}px) rotate(${rotation}deg)`   });
    },[x]);
    useEffect(() => {
       setRotation(Math.floor( Math.random()*60 -  Math.random()*40));
    },[active]);


    return (
      <div className={  cn({
          ["CursorElement"]: true,
          ["--active"]: active === true,
        })}
          ref={elementRef}
        >
        <div className="CursorElement__Content">
          {children}
        </div>
      </div>
    );

}
export default CursorElement;
