import "./UserTurn.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import Button from 'components/Button/Button'
import Avatar from 'components/Avatar/Avatar'
import AppContext from 'contexts/AppContext';
import ChatContext from 'contexts/ChatContext';
import TextMessage from 'components/TextMessage/TextMessage'
import Element from 'components/Element/Element';
import cn from 'classnames';
import gsap from 'gsap';

function UserTurn({turnData, handleEvent, handleNewTurn}) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  const { inputNode, setInputNode, scroll } = useContext(ChatContext);
  const [turnStatus, setTurnStatus] = useState("running");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if(turnData){
      console.log(turnData);

      gsap.delayedCall(.5, setActive);
      gsap.delayedCall(2, nextTurn);
    }
  }, [turnData]);

  function nextTurn(){
    setTurnStatus("ended");
  }
  function setActive(){
    setIsActive(true);
    if(scroll){
      scroll.scrollTo("bottom");
    }
  }



  useEffect(() => {
    if(turnStatus === "ended"){
      console.log("TURNO TERMINADO");
    //  setTurnStatus("ended");

      console.log("CAMBIAR DE TURNO AUTOMATICAMENTE", turnData.target);
      handleNewTurn({name: "automatic-new-turn", link: turnData.target })


    }
  }, [turnStatus]);




    return (
      <div className={  cn({
          ["UserTurn"]: true,
          ["--dark"]: currentSection === "jofre",
          ["--active"]: isActive === true
          })}
        >
        <div className="UserTurn__Avatar">
          <Avatar type="user"/>
        </div>
        <div className="UserTurn__Messages">
          <Element customClass="UserTurn__Message__Element" >
            <div className="UserTurn__Message__Bubble">
               <div className="UserTurn__Message__Bubble__container">
                 {turnData.text}
               </div>
             </div>
          </Element>

        </div>



      </div>
    );

}
export default UserTurn;
