export const dialogData = [{
  "_version": "1.0",
  "project": {
    "_id": "612f9613785931001b080bac",
    "name": "Rueda Photo",
    "teamID": "bKn50J9j9m",
    "devVersion": "612f9613a2e5d70006128cd8",
    "platform": "chatbot",
    "platformData": {
      "invocationName": "template project general"
    },
    "members": [],
    "linkType": "STRAIGHT",
    "image": "",
    "creatorID": 71547,
    "createdAt": "2021-09-01T15:02:43.000Z"
  },
  "version": {
    "_id": "612f9613a2e5d70006128cd8",
    "name": "Initial Version",
    "projectID": "612f9613785931001b080bac",
    "variables": [
      "sessions",
      "user_id",
      "timestamp",
      "platform",
      "locale"
    ],
    "platformData": {
      "slots": [],
      "intents": [],
      "settings": {
        "restart": true,
        "repeat": 100,
        "locales": [
          "es-ES"
        ]
      },
      "publishing": {},
      "platform": "general"
    },
    "rootDiagramID": "612f9613a2e5d70006128cd9",
    "creatorID": 71547,
    "prototype": {
      "data": {
        "name": "Rueda Photo",
        "locales": [
          "es-ES"
        ]
      },
      "model": {
        "intents": [],
        "slots": []
      },
      "context": {
        "stack": [
          {
            "storage": {},
            "variables": {},
            "programID": "612f9613a2e5d70006128cd9"
          }
        ]
      },
      "settings": {},
      "platform": "chatbot"
    }
  },
  "diagrams": {
    "612f9613a2e5d70006128cd9": {
      "_id": "612f9613a2e5d70006128cd9",
      "offsetX": -3580.0000183105476,
      "offsetY": -1766.1999816894531,
      "zoom": 80,
      "nodes": {
        "start00000000000000000000": {
          "nodeID": "start00000000000000000000",
          "type": "start",
          "coords": [
            363.75,
            15
          ],
          "data": {
            "name": "Start",
            "color": "standard",
            "ports": [
              {
                "type": "",
                "target": "613b5c04a95bae1dadfa4883",
                "id": "6032afcf359e8c14c06c0319",
                "data": {
                  "points": [
                    {
                      "point": [
                        528.750015258789,
                        94.99998474121092
                      ]
                    },
                    {
                      "point": [
                        617.7500152587891,
                        93.99999999999999
                      ]
                    }
                  ]
                }
              }
            ],
            "steps": []
          }
        },
        "613b5c04a95bae1dadfa4883": {
          "nodeID": "613b5c04a95bae1dadfa4883",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "bp6396j",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Hola, soy Celeste. Estoy con Daiana, mi compañera de "
                      },
                      {
                        "text": "Rueda Photos",
                        "fontWeight": "700"
                      },
                      {
                        "text": ". Acabamos de entrar a la cárcel. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "61408722ac749f00b316445d",
                "id": "613b5c04a95bae1dadfa4885",
                "data": {}
              }
            ]
          }
        },
        "613b5c04a95bae1dadfa4886": {
          "nodeID": "613b5c04a95bae1dadfa4886",
          "type": "block",
          "coords": [
            783.75,
            15
          ],
          "data": {
            "name": "Jofre - 1. Intro",
            "color": "standard",
            "steps": [
              "613b5c04a95bae1dadfa4883",
              "61408722ac749f00b316445d",
              "613b7cf1a95bae1dadfa48ac"
            ]
          }
        },
        "613b5d1ca95bae1dadfa488e": {
          "nodeID": "613b5d1ca95bae1dadfa488e",
          "type": "visual",
          "data": {
            "name": "Visuals",
            "image": "https://s3.amazonaws.com/com.getstoryflow.api.images/1631280417620-paredviedma.jpg",
            "device": null,
            "dimensions": {
              "width": 2300,
              "height": 1889
            },
            "visualType": "image",
            "canvasVisibility": "full",
            "blockColor": "standard",
            "frameType": "AUTO",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "613b5d1ca95bae1dadfa4890",
                "data": {}
              }
            ]
          }
        },
        "613b5d1ca95bae1dadfa4891": {
          "nodeID": "613b5d1ca95bae1dadfa4891",
          "type": "block",
          "coords": [
            2341.25,
            -77.5
          ],
          "data": {
            "name": "New Block 2",
            "color": "standard",
            "steps": [
              "613b5d1ca95bae1dadfa488e"
            ]
          }
        },
        "613b7cf1a95bae1dadfa48ac": {
          "nodeID": "613b7cf1a95bae1dadfa48ac",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "md2739d9",
                "name": "Por qué hacen eso",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "702939gu",
                "name": "No lo hagan",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "613b7cf1a95bae1dadfa48af",
                "data": {}
              },
              {
                "type": "",
                "target": "613b7d5ea95bae1dadfa48b6",
                "id": "613b7cf1a95bae1dadfa48b0",
                "data": {
                  "points": [
                    {
                      "point": [
                        948.7499313354489,
                        285.00000762939464
                      ]
                    },
                    {
                      "point": [
                        1010.9999465942378,
                        285.00000762939464
                      ]
                    },
                    {
                      "point": [
                        1010.9999465942378,
                        205.49996185302746
                      ]
                    },
                    {
                      "point": [
                        1073.249961853027,
                        205.49996185302746
                      ]
                    }
                  ]
                }
              },
              {
                "type": "2",
                "target": "613cfa3a4991c23bc7ac5959",
                "id": "613b7d01a95bae1dadfa48b4",
                "data": {
                  "points": [
                    {
                      "point": [
                        948.7500381469748,
                        360.99999999999966
                      ]
                    },
                    {
                      "point": [
                        1003.0000076293966,
                        360.99999999999966
                      ]
                    },
                    {
                      "point": [
                        1003.0000076293966,
                        486.5000152587888
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        1057.2499771118185,
                        486.5000152587888
                      ]
                    }
                  ]
                }
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktej39md00263h69j21fi7vw",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "613b7d5ea95bae1dadfa48b6": {
          "nodeID": "613b7d5ea95bae1dadfa48b6",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "9a2u39iz",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Queremos entender. Saber quienes son, por qué lo hicieron. Por qué lo hacen."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "613cfa114991c23bc7ac594f",
                "id": "613b7d5ea95bae1dadfa48b8",
                "data": {}
              }
            ]
          }
        },
        "613b7d5ea95bae1dadfa48b9": {
          "nodeID": "613b7d5ea95bae1dadfa48b9",
          "type": "block",
          "coords": [
            1239.25,
            126.49999999999999
          ],
          "data": {
            "name": "Jofre - 2. Por qué",
            "color": "standard",
            "steps": [
              "613b7d5ea95bae1dadfa48b6"
            ]
          }
        },
        "613cfa114991c23bc7ac594c": {
          "nodeID": "613cfa114991c23bc7ac594c",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "3o63anh",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Mirá, es él."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "613cfaae4991c23bc7ac5968",
                "id": "613cfa114991c23bc7ac594e",
                "data": {}
              }
            ]
          }
        },
        "613cfa114991c23bc7ac594f": {
          "nodeID": "613cfa114991c23bc7ac594f",
          "type": "block",
          "coords": [
            1668.4999999999998,
            270.49999999999994
          ],
          "data": {
            "name": "Jofre - 3. Presentación",
            "color": "standard",
            "steps": [
              "613cfa114991c23bc7ac594c",
              "613cfaae4991c23bc7ac5968",
              "613cfaf64991c23bc7ac5970",
              "613cfb084991c23bc7ac5978"
            ]
          }
        },
        "613cfa3a4991c23bc7ac5956": {
          "nodeID": "613cfa3a4991c23bc7ac5956",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "r9a3al8",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Tarde. Ya está por llegar. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "613cfa114991c23bc7ac594f",
                "id": "613cfa3a4991c23bc7ac5958",
                "data": {
                  "points": [
                    {
                      "point": [
                        1387.2500228881856,
                        535.4999694824215
                      ]
                    },
                    {
                      "point": [
                        1445.3750381469747,
                        535.4999694824215
                      ]
                    },
                    {
                      "point": [
                        1445.3750381469747,
                        301.5000152587888
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        1503.5000534057638,
                        301.5000152587888
                      ]
                    }
                  ]
                }
              }
            ]
          }
        },
        "613cfa3a4991c23bc7ac5959": {
          "nodeID": "613cfa3a4991c23bc7ac5959",
          "type": "block",
          "coords": [
            1222.2499999999998,
            455.49999999999994
          ],
          "data": {
            "name": "Jofre - 2. No lo hagan",
            "color": "standard",
            "steps": [
              "613cfa3a4991c23bc7ac5956"
            ]
          }
        },
        "613cfaae4991c23bc7ac5968": {
          "nodeID": "613cfaae4991c23bc7ac5968",
          "type": "visual",
          "data": {
            "name": "Visuals",
            "image": "https://s3.amazonaws.com/com.getstoryflow.api.images/1631386291937-02_jofre.jpg",
            "device": null,
            "dimensions": {
              "width": 3543,
              "height": 2365
            },
            "visualType": "image",
            "canvasVisibility": "full",
            "blockColor": "standard",
            "frameType": "AUTO",
            "ports": [
              {
                "type": "",
                "target": "613cfaf64991c23bc7ac5970",
                "id": "613cfaae4991c23bc7ac596b",
                "data": {}
              }
            ]
          }
        },
        "613cfaf64991c23bc7ac5970": {
          "nodeID": "613cfaf64991c23bc7ac5970",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "dhg3a6x",
                "content": [
                  {
                    "children": [
                      {
                        "text": "¿Te suena conocido?"
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "613cfb084991c23bc7ac5978",
                "id": "613cfaf64991c23bc7ac5973",
                "data": {}
              }
            ]
          }
        },
        "613cfb084991c23bc7ac5978": {
          "nodeID": "613cfb084991c23bc7ac5978",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "luk3a1f",
                "name": "Tal vez sí",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "cnm3ayl",
                "name": "No, para nada",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "613cfb084991c23bc7ac597b",
                "data": {}
              },
              {
                "type": "",
                "target": "613cfb274991c23bc7ac5985",
                "id": "613cfb084991c23bc7ac597c",
                "data": {
                  "points": [
                    {
                      "point": [
                        1833.5000228881834,
                        735.390640258789
                      ]
                    },
                    {
                      "point": [
                        1870.3750381469724,
                        735.390640258789
                      ]
                    },
                    {
                      "point": [
                        1870.3750381469724,
                        596.5000152587891
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        1907.2500534057615,
                        596.5000152587891
                      ]
                    }
                  ]
                }
              },
              {
                "type": "2",
                "target": "613cfb7d4991c23bc7ac598d",
                "id": "613cfb194991c23bc7ac5980",
                "data": {
                  "points": [
                    {
                      "point": [
                        1833.5000152587893,
                        789.3906564712526
                      ]
                    },
                    {
                      "point": [
                        2048.2499923706055,
                        789.3906564712526
                      ]
                    },
                    {
                      "point": [
                        2048.2499923706055,
                        982.0000333786012
                      ]
                    },
                    {
                      "point": [
                        2262.999969482422,
                        982.0000333786012
                      ]
                    }
                  ]
                }
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktg5bslu000j3h6a5bf4993x",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "613cfb274991c23bc7ac5982": {
          "nodeID": "613cfb274991c23bc7ac5982",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "xuo3aux",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Quizás no sea muy distinto a la mayoría de los hombres que conocés.  "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "613cfb7d4991c23bc7ac5990",
                "id": "613cfb274991c23bc7ac5984",
                "data": {
                  "points": [
                    {
                      "point": [
                        2237.250015258789,
                        667.5000305175782
                      ]
                    },
                    {
                      "point": [
                        2429,
                        667.5000305175782
                      ]
                    },
                    {
                      "toTop": true,
                      "allowedToTop": true,
                      "point": [
                        2429,
                        902.0000209808352
                      ]
                    }
                  ]
                }
              }
            ]
          }
        },
        "613cfb274991c23bc7ac5985": {
          "nodeID": "613cfb274991c23bc7ac5985",
          "type": "block",
          "coords": [
            2072.25,
            565.4999999999999
          ],
          "data": {
            "name": "Jofre - 4. Me suena conocido",
            "color": "standard",
            "steps": [
              "613cfb274991c23bc7ac5982"
            ]
          }
        },
        "613cfb7d4991c23bc7ac598d": {
          "nodeID": "613cfb7d4991c23bc7ac598d",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "a1r3a0i",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Se llama Pablo Jofré.  Es uno de los condenados por el femicidio de Karen Alvarez. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140a17fac749f00b31644a4",
                "id": "613cfb7d4991c23bc7ac598f",
                "data": {}
              }
            ]
          }
        },
        "613cfb7d4991c23bc7ac5990": {
          "nodeID": "613cfb7d4991c23bc7ac5990",
          "type": "block",
          "coords": [
            2429.0000228881836,
            902.9999771118164
          ],
          "data": {
            "name": "Jofre - 4. No me suena",
            "color": "standard",
            "steps": [
              "613cfb7d4991c23bc7ac598d",
              "6140a17fac749f00b31644a4",
              "6140ab36ac749f00b31644bf",
              "6140ae9fac749f00b316453c"
            ]
          }
        },
        "61408722ac749f00b316445d": {
          "nodeID": "61408722ac749f00b316445d",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "fa739cd",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Vamos a entrevistar a un femicida."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "613b7cf1a95bae1dadfa48ac",
                "id": "61408722ac749f00b3164460",
                "data": {}
              }
            ]
          }
        },
        "61408c92ac749f00b316446d": {
          "nodeID": "61408c92ac749f00b316446d",
          "type": "visual",
          "data": {
            "name": "Visuals",
            "image": "https://s3.amazonaws.com/com.getstoryflow.api.images/1631620259907-3jofre3.jpg",
            "device": null,
            "dimensions": {
              "width": 1200,
              "height": 801
            },
            "visualType": "image",
            "canvasVisibility": "full",
            "blockColor": "standard",
            "frameType": "AUTO",
            "ports": [
              {
                "type": "",
                "target": "6140b646ac749f00b31645b6",
                "id": "61408c92ac749f00b316446f",
                "data": {}
              }
            ]
          }
        },
        "61408c92ac749f00b3164470": {
          "nodeID": "61408c92ac749f00b3164470",
          "type": "block",
          "coords": [
            4817.5,
            2071.25
          ],
          "data": {
            "name": "Jofre - 8. Acepta que es femicida",
            "color": "standard",
            "steps": [
              "6140b615ac749f00b31645af",
              "61408c92ac749f00b316446d",
              "6140b646ac749f00b31645b6",
              "6140b664ac749f00b31645be",
              "6140b68aac749f00b31645c6"
            ]
          }
        },
        "61408cbeac749f00b316447b": {
          "nodeID": "61408cbeac749f00b316447b",
          "type": "visual",
          "data": {
            "name": "Visuals",
            "image": "https://s3.amazonaws.com/com.getstoryflow.api.images/1631620293953-jofre-mas-joven.png",
            "device": null,
            "dimensions": {
              "width": 1170,
              "height": 906
            },
            "visualType": "image",
            "canvasVisibility": "full",
            "blockColor": "standard",
            "frameType": "AUTO",
            "ports": [
              {
                "type": "",
                "target": "61433f25a861e8b530474568",
                "id": "61408cbeac749f00b316447d",
                "data": {}
              }
            ]
          }
        },
        "61408cbeac749f00b316447e": {
          "nodeID": "61408cbeac749f00b316447e",
          "type": "block",
          "coords": [
            6175.556841070004,
            2445.045431657271
          ],
          "data": {
            "name": "Jofre - 10. Quien es",
            "color": "standard",
            "steps": [
              "61433934a861e8b53047455a",
              "61408cbeac749f00b316447b",
              "61433f25a861e8b530474568",
              "6143455fa861e8b530474570"
            ]
          }
        },
        "6140a17fac749f00b31644a4": {
          "nodeID": "6140a17fac749f00b31644a4",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "b74k395z",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Ella tenía 14 años. La mataron en 2014. Su cuerpo apareció en un descampado en Viedma, Rio Negro."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140ab36ac749f00b31644bf",
                "id": "6140a17fac749f00b31644a7",
                "data": {}
              }
            ]
          }
        },
        "6140ab36ac749f00b31644bf": {
          "nodeID": "6140ab36ac749f00b31644bf",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "ws6939fu",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Momento, tiene que empezar la entrevista."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140ae9fac749f00b316453c",
                "id": "6140ab36ac749f00b31644c1",
                "data": {}
              }
            ]
          }
        },
        "6140ae9fac749f00b316453c": {
          "nodeID": "6140ae9fac749f00b316453c",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "oi7h39tv",
                "name": "¿Qué está pasando?",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "gc7l39vg",
                "name": "¿Como se sienten?",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "6140ae9fac749f00b316453f",
                "data": {}
              },
              {
                "type": "",
                "target": "6140aef9ac749f00b3164549",
                "id": "6140ae9fac749f00b3164540",
                "data": {
                  "points": [
                    {
                      "point": [
                        2594.0000640966714,
                        1300.9999922982465
                      ]
                    },
                    {
                      "point": [
                        2755.6366193455915,
                        1300.9999922982465
                      ]
                    },
                    {
                      "point": [
                        2755.6366193455915,
                        980.8659609346566
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        2917.2731745945116,
                        980.8659609346566
                      ]
                    }
                  ]
                }
              },
              {
                "type": "2",
                "target": "6140b017ac749f00b316455c",
                "id": "6140aedaac749f00b3164544",
                "data": {
                  "points": [
                    {
                      "point": [
                        2594.0000640966714,
                        1354.9999908107125
                      ]
                    },
                    {
                      "point": [
                        2756.0335755048836,
                        1354.9999908107125
                      ]
                    },
                    {
                      "point": [
                        2756.0335755048836,
                        1748.528413616924
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        2918.0670869130954,
                        1748.528413616924
                      ]
                    }
                  ]
                }
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktk5r6oi007g3h69tgn07i5n",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "6140aef9ac749f00b3164546": {
          "nodeID": "6140aef9ac749f00b3164546",
          "type": "visual",
          "data": {
            "name": "Visuals",
            "image": "https://s3.amazonaws.com/com.getstoryflow.api.images/1631629107840-img_2396-2.jpg",
            "device": null,
            "dimensions": {
              "width": 3024,
              "height": 4032
            },
            "visualType": "image",
            "canvasVisibility": "full",
            "blockColor": "standard",
            "frameType": "AUTO",
            "ports": [
              {
                "type": "",
                "target": "6140b076ac749f00b3164567",
                "id": "6140aef9ac749f00b3164548",
                "data": {
                  "points": [
                    {
                      "point": [
                        3247.2729512871456,
                        1113.8660766903463
                      ]
                    },
                    {
                      "point": [
                        3394.01120384494,
                        1113.8660766903463
                      ]
                    },
                    {
                      "point": [
                        3394.01120384494,
                        1902.5002319393734
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        3540.7494564027347,
                        1902.5002319393734
                      ]
                    }
                  ]
                }
              }
            ]
          }
        },
        "6140aef9ac749f00b3164549": {
          "nodeID": "6140aef9ac749f00b3164549",
          "type": "block",
          "coords": [
            3082.2732110613333,
            949.8659507496913
          ],
          "data": {
            "name": "Jofre - 5. Qué está pasando",
            "color": "standard",
            "steps": [
              "6140af57ac749f00b3164552",
              "6140aef9ac749f00b3164546"
            ]
          }
        },
        "6140af57ac749f00b3164552": {
          "nodeID": "6140af57ac749f00b3164552",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "7w7o3980",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Está llegando. En la cárcel nos dieron un cuarto muy chico. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140aef9ac749f00b3164546",
                "id": "6140af57ac749f00b3164555",
                "data": {}
              }
            ]
          }
        },
        "6140b017ac749f00b3164559": {
          "nodeID": "6140b017ac749f00b3164559",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "hq9i39mk",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Le tengo que poner el micrófono inalámbrico. Me tiemblan las manos. Creo que él lo nota. Sonrie. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140b076ac749f00b3164567",
                "id": "6140b017ac749f00b316455b",
                "data": {
                  "points": [
                    {
                      "point": [
                        3248.0667784828224,
                        1830.528430484552
                      ]
                    },
                    {
                      "point": [
                        3394.4081174427783,
                        1830.528430484552
                      ]
                    },
                    {
                      "point": [
                        3394.4081174427783,
                        1902.5002319393734
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        3540.7494564027347,
                        1902.5002319393734
                      ]
                    }
                  ]
                }
              }
            ]
          }
        },
        "6140b017ac749f00b316455c": {
          "nodeID": "6140b017ac749f00b316455c",
          "type": "block",
          "coords": [
            3083.0670459008843,
            1717.528320397141
          ],
          "data": {
            "name": "Jofre - 5. Como se sienten",
            "color": "standard",
            "steps": [
              "6140b017ac749f00b3164559"
            ]
          }
        },
        "6140b076ac749f00b3164564": {
          "nodeID": "6140b076ac749f00b3164564",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "qw9n39k6",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Acepta hablar del asesinato. Dice que para él, matar a una persona es cómo matar a un perro:"
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140b506ac749f00b316457b",
                "id": "6140b076ac749f00b3164566",
                "data": {}
              }
            ]
          }
        },
        "6140b076ac749f00b3164567": {
          "nodeID": "6140b076ac749f00b3164567",
          "type": "block",
          "coords": [
            3705.750022888183,
            1871.4999771118164
          ],
          "data": {
            "name": "Jofre - 6. Acepta hablar",
            "color": "standard",
            "steps": [
              "6140b076ac749f00b3164564",
              "6140b506ac749f00b316457b",
              "6140b54dac749f00b3164583",
              "6140b57eac749f00b316458b"
            ]
          }
        },
        "6140b506ac749f00b316457b": {
          "nodeID": "6140b506ac749f00b316457b",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "cqbw394z",
                "content": [
                  {
                    "children": [
                      {
                        "text": ""
                      },
                      {
                        "url": "https://drive.google.com/file/d/17uEBDzxM9VBue4qFixHYFTqm_VL3cc9d/view?usp=sharing",
                        "type": "link",
                        "children": [
                          {
                            "text": "[AUDIO CRIMEN ]",
                            "color": {
                              "r": 93,
                              "g": 157,
                              "b": 245,
                              "a": 1
                            },
                            "underline": true
                          }
                        ]
                      },
                      {
                        "text": ""
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140b54dac749f00b3164583",
                "id": "6140b506ac749f00b316457e",
                "data": {}
              }
            ]
          }
        },
        "6140b54dac749f00b3164583": {
          "nodeID": "6140b54dac749f00b3164583",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "z0dm39zc",
                "content": [
                  {
                    "children": [
                      {
                        "text": " ¿Querés escuchar más?"
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140b57eac749f00b316458b",
                "id": "6140b54dac749f00b3164586",
                "data": {}
              }
            ]
          }
        },
        "6140b57eac749f00b316458b": {
          "nodeID": "6140b57eac749f00b316458b",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "opdp39bv",
                "name": "Sí",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "zcdr399o",
                "name": "No",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "6140b57eac749f00b316458e",
                "data": {}
              },
              {
                "type": "",
                "target": "6140b5bcac749f00b31645a4",
                "id": "6140b57eac749f00b316458f",
                "data": {
                  "points": [
                    {
                      "point": [
                        3870.7500762939453,
                        2203.5000343322754
                      ]
                    },
                    {
                      "point": [
                        3968.3750610351562,
                        2203.5000343322754
                      ]
                    },
                    {
                      "point": [
                        3968.3750610351562,
                        1948.0000267028809
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        4066.000045776367,
                        1948.0000267028809
                      ]
                    }
                  ]
                }
              },
              {
                "type": "2",
                "target": "6140b59aac749f00b3164598",
                "id": "6140b58aac749f00b3164593",
                "data": {}
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktk6svop00do3h69lqi94ayk",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "6140b59aac749f00b3164595": {
          "nodeID": "6140b59aac749f00b3164595",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "dndu393i",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Te entedemos. Nosotras tampoco. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "61408c92ac749f00b3164470",
                "id": "6140b59aac749f00b3164597",
                "data": {}
              }
            ]
          }
        },
        "6140b59aac749f00b3164598": {
          "nodeID": "6140b59aac749f00b3164598",
          "type": "block",
          "coords": [
            4227.250022888184,
            2299.4999771118164
          ],
          "data": {
            "name": "Jofre - 7. No escuchar más",
            "color": "standard",
            "steps": [
              "6140b59aac749f00b3164595"
            ]
          }
        },
        "6140b5bcac749f00b31645a1": {
          "nodeID": "6140b5bcac749f00b31645a1",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "k7dy39l5",
                "content": [
                  {
                    "children": [
                      {
                        "text": ""
                      },
                      {
                        "url": "https://drive.google.com/file/d/1_AMRgAPin9NBKPddttqEaJBsuHqTl6PE/view?usp=sharing",
                        "type": "link",
                        "children": [
                          {
                            "text": "[AUDIO CRIMEN 2]",
                            "color": {
                              "r": 93,
                              "g": 157,
                              "b": 245,
                              "a": 1
                            },
                            "underline": true
                          }
                        ]
                      },
                      {
                        "text": ""
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "61408c92ac749f00b3164470",
                "id": "6140b5bcac749f00b31645a3",
                "data": {
                  "points": [
                    {
                      "point": [
                        4396.000091552734,
                        1997.0000381469727
                      ]
                    },
                    {
                      "point": [
                        4524.250076293945,
                        1997.0000381469727
                      ]
                    },
                    {
                      "point": [
                        4524.250076293945,
                        2102.2500381469727
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        4652.500061035156,
                        2102.2500381469727
                      ]
                    }
                  ]
                }
              }
            ]
          }
        },
        "6140b5bcac749f00b31645a4": {
          "nodeID": "6140b5bcac749f00b31645a4",
          "type": "block",
          "coords": [
            4231.000022888184,
            1916.9999771118164
          ],
          "data": {
            "name": "Jofre - 7. Escuchar más",
            "color": "standard",
            "steps": [
              "6140b5bcac749f00b31645a1"
            ]
          }
        },
        "6140b615ac749f00b31645af": {
          "nodeID": "6140b615ac749f00b31645af",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "5dfs39i3",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Es la segunda vez que nos pasa: tipos que no tienen problemas en aceptar que son femicidas. Jofré dice que se arrepiente, pero que Dios permitió esa muerte."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "61408c92ac749f00b316446d",
                "id": "6140b615ac749f00b31645b2",
                "data": {}
              }
            ]
          }
        },
        "6140b646ac749f00b31645b6": {
          "nodeID": "6140b646ac749f00b31645b6",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "tvfv39xv",
                "content": [
                  {
                    "children": [
                      {
                        "text": "También dice que le pagaron, que para él fue solo eso: un trabajo. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140b664ac749f00b31645be",
                "id": "6140b646ac749f00b31645b9",
                "data": {}
              }
            ]
          }
        },
        "6140b664ac749f00b31645be": {
          "nodeID": "6140b664ac749f00b31645be",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "dqfx39o4",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Pero nosotras sabemos que miente. Sabemos quién es. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140b68aac749f00b31645c6",
                "id": "6140b664ac749f00b31645c1",
                "data": {}
              }
            ]
          }
        },
        "6140b68aac749f00b31645c6": {
          "nodeID": "6140b68aac749f00b31645c6",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "mqg039yd",
                "name": "Por qué lo saben",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "vgg2397o",
                "name": "Quién es",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "6140b68aac749f00b31645c9",
                "data": {}
              },
              {
                "type": "",
                "target": "6140b738ac749f00b31645d0",
                "id": "6140b68aac749f00b31645ca",
                "data": {
                  "points": [
                    {
                      "point": [
                        4982.5001525878915,
                        2752.1405639648433
                      ]
                    },
                    {
                      "point": [
                        5169.500133514405,
                        2752.1405639648433
                      ]
                    },
                    {
                      "point": [
                        5169.500133514405,
                        2550.2499237060542
                      ]
                    },
                    {
                      "point": [
                        5356.500114440919,
                        2550.2499237060542
                      ]
                    }
                  ]
                }
              },
              {
                "type": "2",
                "target": "6140b78bac749f00b31645de",
                "id": "6140b693ac749f00b31645ce",
                "data": {
                  "points": [
                    {
                      "point": [
                        4982.500030517578,
                        2806.1406250000005
                      ]
                    },
                    {
                      "point": [
                        5162.625015258789,
                        2806.1406250000005
                      ]
                    },
                    {
                      "point": [
                        5162.625015258789,
                        3144.000030517578
                      ]
                    },
                    {
                      "allowedToTop": true,
                      "point": [
                        5342.750000000001,
                        3144.000030517578
                      ]
                    }
                  ]
                }
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktk6ymmp00fz3h69v53igjcv",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "6140b738ac749f00b31645d0": {
          "nodeID": "6140b738ac749f00b31645d0",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "s6gc392x",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Primero, porque la autopsia dice otra cosa.  "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6143383da861e8b530474539",
                "id": "6140b738ac749f00b31645d2",
                "data": {}
              }
            ]
          }
        },
        "6140b738ac749f00b31645d3": {
          "nodeID": "6140b738ac749f00b31645d3",
          "type": "block",
          "coords": [
            5522.5000228881845,
            2471.2499771118164
          ],
          "data": {
            "name": "Jofre - 9. Por qué saben que miente",
            "color": "standard",
            "steps": [
              "6140b738ac749f00b31645d0",
              "6143383da861e8b530474539",
              "614338a2a861e8b530474541"
            ]
          }
        },
        "6140b78bac749f00b31645db": {
          "nodeID": "6140b78bac749f00b31645db",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "27io3975",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Es una pregunta dificil de responder. En principio, un mentiroso. Un fabulador."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140d3d1ac749f00b31645f6",
                "id": "6140b78bac749f00b31645dd",
                "data": {}
              }
            ]
          }
        },
        "6140b78bac749f00b31645de": {
          "nodeID": "6140b78bac749f00b31645de",
          "type": "block",
          "coords": [
            5507.750022888185,
            3112.999977111816
          ],
          "data": {
            "name": "Jofre - 9. Quien es",
            "color": "standard",
            "steps": [
              "6140b78bac749f00b31645db",
              "6140d3d1ac749f00b31645f6",
              "6140d3ecac749f00b31645fe"
            ]
          }
        },
        "6140d3d1ac749f00b31645f6": {
          "nodeID": "6140d3d1ac749f00b31645f6",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "lyoi39zt",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Intentamos reconstruir su historia. Y adiviná qué..."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6140d3ecac749f00b31645fe",
                "id": "6140d3d1ac749f00b31645f9",
                "data": {}
              }
            ]
          }
        },
        "6140d3ecac749f00b31645fe": {
          "nodeID": "6140d3ecac749f00b31645fe",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "ywol396r",
                "name": "No quiso contarla",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "bxon39ip",
                "name": "Es muy complicada",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "6140d3ecac749f00b3164601",
                "data": {}
              },
              {
                "type": "",
                "target": null,
                "id": "6140d3ecac749f00b3164602",
                "data": {}
              },
              {
                "type": "2",
                "target": null,
                "id": "6140d3f7ac749f00b3164606",
                "data": {}
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktkbfuyw00ok3h696gvjcen3",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "6143383da861e8b530474539": {
          "nodeID": "6143383da861e8b530474539",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "11839uk",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Segundo, porque es alguien acostumbrado a mentir.  Vivió gran parte de su vida así."
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "614338a2a861e8b530474541",
                "id": "6143383da861e8b53047453c",
                "data": {}
              }
            ]
          }
        },
        "614338a2a861e8b530474541": {
          "nodeID": "614338a2a861e8b530474541",
          "type": "buttons",
          "data": {
            "name": "Buttons",
            "buttons": [
              {
                "id": "vkc39tb",
                "name": "Qué dice la autopsia.",
                "actions": [
                  "PATH"
                ]
              },
              {
                "id": "lof39fq",
                "name": "Por qué dicen eso de las mentiras",
                "actions": [
                  "PATH"
                ]
              }
            ],
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "614338a2a861e8b530474544",
                "data": {}
              },
              {
                "type": "",
                "target": null,
                "id": "614338a2a861e8b530474545",
                "data": {}
              },
              {
                "type": "2",
                "target": "61408cbeac749f00b316447e",
                "id": "614338aba861e8b530474549",
                "data": {}
              }
            ],
            "else": {
              "type": "reprompt",
              "pathName": "No Match",
              "randomize": false,
              "reprompts": [
                {
                  "id": "cktmwtrvj000b3h69eyxhxb0u",
                  "content": [
                    {
                      "children": [
                        {
                          "text": ""
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            "reprompt": null
          }
        },
        "61433934a861e8b53047455a": {
          "nodeID": "61433934a861e8b53047455a",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "pp343916",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Mirá, este es él cuando lo detuvieron, en 2013:"
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "61408cbeac749f00b316447b",
                "id": "61433934a861e8b53047455c",
                "data": {}
              }
            ]
          }
        },
        "61433f25a861e8b530474568": {
          "nodeID": "61433f25a861e8b530474568",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "lq6539h7",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Antes de verl a Jofré, hablamos con la Comisaria Carrizo. Ella lo detuvo. "
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": "6143455fa861e8b530474570",
                "id": "61433f25a861e8b53047456b",
                "data": {}
              }
            ]
          }
        },
        "6143455fa861e8b530474570": {
          "nodeID": "6143455fa861e8b530474570",
          "type": "text",
          "data": {
            "name": "Text",
            "texts": [
              {
                "id": "vy95391h",
                "content": [
                  {
                    "children": [
                      {
                        "text": "Ella nos que  Jofré tenía seis identidades distintas. Nunca"
                      }
                    ]
                  }
                ]
              }
            ],
            "canvasVisibility": "preview",
            "blockColor": "standard",
            "ports": [
              {
                "type": "",
                "target": null,
                "id": "6143455fa861e8b530474573",
                "data": {}
              }
            ]
          }
        }
      },
      "variables": [],
      "children": [],
      "name": "ROOT",
      "versionID": "612f9613a2e5d70006128cd8",
      "creatorID": 71547,
      "modified": 1632848172
    }
  }
}];
