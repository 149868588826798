// import "./VisualMessage.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import AppContext from 'contexts/AppContext';
import Element from 'components/Element/Element';
import TurnContext from 'contexts/TurnContext';
import cn from 'classnames';
import gsap from 'gsap';

function VisualMessage({node, index, handleEvent}) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  const {  messageTurn, setMessageTurn } = useContext(TurnContext);
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {

      if(messageTurn === index){
        console.log("UN MENSAJE ESTA ACTIVO!", index);
        setIsActive(true);
        console.log("VISUAL NODE", node);
      }

  }, [messageTurn]);

  useEffect(()=>{

    if(isActive === true){
      gsap.delayedCall(1, nextTurn);

    }
  }, [isActive])

  function nextTurn(){
    setMessageTurn(index+1);
  }





    return (
      <div className={  cn({
          ["VisualMessage"]: true,
          ["--active"]: isActive === true
          })}
        >
        <Element customClass="VisualMessage__Element" visibleAction={(e) => handleEvent(e)} name={node.data.image}>

        </Element>
      </div>
    );

}
export default VisualMessage;
