import React, { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion"
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import AppContext from 'contexts/AppContext';
import ChatContext from 'contexts/ChatContext';
import Chat from 'components/Chat/Chat'
import ChatInput from 'components/ChatInput/ChatInput'
import FixedContent from 'components/FixedContent/FixedContent'
import cn from 'classnames';
import { dialogData }from "dialog/DialogData";
import useMouse from '@react-hook/mouse-position'

import "./Jofre.scss";

function Jofre() {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  //chatContext
  const [nodes, setNodes] = useState();
  const [blocks, setBlocks] = useState();
  const [inputNode, setInputNode] = useState("empty");
 //
  const [turns, setTurns] = useState();
  const [currentTurn, setCurrentTurn] = useState(0);
  //scroll
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {
    setCurrentSection("jofre");
    if(scroll){
      scroll.destroy();
      scroll.init();
    }
  }, [scroll]);
  //MOUSE HOOKS
  const hoverRef = React.useRef(null)
  const mouse = useMouse(hoverRef, {
     enterDelay: 100,
     leaveDelay: 100,
  })

  //Set Nodes --> All nodes in a variable
  useEffect(() => {
    if(dialogData){
      var diagrams = dialogData[0].diagrams;
      var diagramNodes = Object.values(diagrams)[0].nodes
      setNodes(Object.values(diagramNodes))

    }
  }, [dialogData]);

  //Set Blocks --> Object with only blocks (not individual nodes)
  useEffect(() => {
    if(nodes){
      if(nodes.length > 0){
        var blocksObject = nodes.map((node) => {
            if(node.type === "block"){
              return node;
            }else {
              return (null);
            }
        });
        //console.log("final object", blocksObject.filter(x => x !== null));
        setBlocks(blocksObject.filter(x => x !== null))
      }
    }
  }, [nodes]);
  //BLOCK Object --> With blocks ready we can start the turn
  useEffect(() => {

    if(blocks){
      //find starting node & target
      var startNode = nodes.find(node => node.type === "start");
      var startNodeTarget = startNode.data.ports[0].target;
      //find start block
      var startBlock = blocks.map((node, index) => {
          var step = node.data.steps.find(step => step === startNodeTarget);
          if(step !== undefined){
            //Set the first turn --> Turns are blocks index with type: bot or user turn
            setTurns([{index: index, type:"bot"}]);
          }
      });

    }
  }, [blocks]);

  //Turns
  useEffect(() => {

    if(turns){
      console.log("turns", turns)
    }
  }, [turns]);


  //Handle advanced page transitions
  const pageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0 , transition:{ duration: .5}}
  }
  const pageAnimationStart = e => {
  };
  const pageAnimationComplete = e => {
  };

  //PAGE CODE
  const [activeContent, setActiveContent] = useState();

  //HANDLE MEDIA ELEMENTS BASED ON CHAT
  function handleMessageShown(e) {
    console.log("mostrado",e.inView, e.name);
    if(e.inView === true){
      if(e.name.indexOf("api.images") !== -1){
        setActiveContent(e.name);
      }
    }else {
    //  setActiveContent("");
    }
  }
  //HANDLE TURNS
  function handleNewTurn(e) {
    setInputNode("empty");
    console.log("cambiaria el turno a ",e.link)
    //Add user turn if is a button event
    if(e.type === "button"){
      var userElement = {index: 1, type:"user", text: e.name, target:e.link}
      setTurns(oldTurns => [...oldTurns, userElement]);
    }else{
      //find new block
      var newBlock = blocks.map((node, index) => {
          var finalElement;
          var step = node.data.steps.find(step => step === e.link);
          if(node.nodeID === e.link){
            finalElement = {index: index, type:"bot"};
          }else{
            if(step !== undefined){
              finalElement = {index: index, type:"bot"};
            }
          }
          if(finalElement){
            setTurns(oldTurns => [...oldTurns, finalElement]);

          }
      });
    }
  }


  return (
  <motion.div
    exit="exit"
    animate="visible"
    initial="hidden"
    variants={pageVariants}
    onAnimationStart={pageAnimationStart}
    onAnimationComplete={pageAnimationComplete}
  >

      <div className={  cn({
              ["page"]: true,
              ["jofre"]: true
            })}
      >
        <ChatContext.Provider value={{
            nodes: nodes,
            blocks : blocks,
            setNodes: setNodes,
            setBlocks: setBlocks,
            inputNode: inputNode,
            setInputNode: setInputNode,
            scroll: scroll,
            mouse: mouse
         }}>
          <section data-sroll-section id="scrollTarget" >
            <FixedContent currentContent={activeContent} reference={hoverRef} scrollTarget={"#scrollTarget"} />
            <ChatInput handleNewTurn={(e) => handleNewTurn(e)} scrollTarget={"#scrollTarget"}/>
            <Chat handleNewTurn={(e) => handleNewTurn(e)} scroll={scroll} handleEvent={(e) => handleMessageShown(e)} turns={turns} nodes={nodes} blocks={blocks}/>
          </section>
        </ChatContext.Provider>

      </div>

  </motion.div>
  )
}

export default Jofre;
