import React, { useEffect, useState, useContext, useRef } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import { AnimatePresence } from "framer-motion";
import Home from 'pages/Home/Home';
import About from 'pages/About/About';
import Jofre from 'pages/Jofre/Jofre';
import Menu from 'pages/Menu/Menu';
import Nav from 'components/Nav/Nav';

import AppContext from 'contexts/AppContext';
import ChatContext from 'contexts/ChatContext';
import cn from 'classnames';


function App() {
  const location = useLocation();
  const containerRef = useRef(null);

  //contexts
  const [currentSection, setCurrentSection] = useState("home");

  //
  return (


          <AppContext.Provider value={{
              setCurrentSection: setCurrentSection,
              currentSection : currentSection,
           }}>
          <LocomotiveScrollProvider
            options={{
                smooth: true,
                reloadOnContextChange: false,
            }}
            watch={[] }
            containerRef={containerRef}
          >

            <div  className =  {  cn({
                ["App"]: true,
                ["App--dark"]: currentSection === "jofre",
              })}>

              <Nav />

              <main data-scroll-container ref={containerRef}>
                <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname} >

                    <Route path="/" exact>
                      <Home />
                    </Route>

                    <Route path="/about" exact>
                      <About />
                    </Route>

                    <Route path="/jofre" exact>
                      <Jofre />
                    </Route>

                    <Route path="/menu" exact>
                      <Menu />
                    </Route>


                  </Switch>
                </AnimatePresence>
              </main>
            </div>

          </LocomotiveScrollProvider>
        </AppContext.Provider>

  );
}

export default App;
