import React, { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion"
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import AppContext from 'contexts/AppContext';
import { NavLink} from 'react-router-dom'
import cn from 'classnames';

function Menu() {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  //scroll
  const { scroll } = useLocomotiveScroll();
  useEffect(() => {
    setCurrentSection("menu");
    if(scroll){
      scroll.destroy();
      scroll.init();
    }
  }, [scroll]);
  //Handle advanced page transitions
  const pageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
    exit: { opacity: 0 , transition:{ duration: .5}}
  }
  const pageAnimationStart = e => {
  };
  const pageAnimationComplete = e => {
  };


  return (
  <motion.div
    exit="exit"
    animate="visible"
    initial="hidden"
    variants={pageVariants}
    onAnimationStart={pageAnimationStart}
    onAnimationComplete={pageAnimationComplete}
  >

    <div className={  cn({
            ["page"]: true,
            ["menu"]: true
          })}
    >

      <section>
        <NavLink  to='/' exact > Home</NavLink> <br/>
        <NavLink  to='/jofre' exact > Jofre</NavLink> <br/>
        <NavLink  to='/mobilio' exact > Mobilio</NavLink> <br/>
        <NavLink  to='/karen' exact > Karen</NavLink>

      </section>
    </div>
  </motion.div>
  )
}

export default Menu;
