import "./Avatar.scss";
import React from "react";
import cn from 'classnames';
import Element from 'components/Element/Element';


function Avatar({type}) {



    return (
      <div className={  cn({
          ["Avatar"]: true,
          ["--user"]: type === "user",
          ["--bot"]: type === "bot",

        })}>
        <Element>
        <div className="Avatar__circle"></div>
        </Element>
      </div>
    );

}
export default Avatar;
