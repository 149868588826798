import "./ChatInput.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import Button from 'components/Button/Button'
import AppContext from 'contexts/AppContext';
import ChatContext from 'contexts/ChatContext';
import Avatar from 'components/Avatar/Avatar'
import cn from 'classnames';

function ChatInput({scrollTarget, handleNewTurn, handleNewUserTurn}) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  const {  nodes, inputNode, setInputNode } = useContext(ChatContext);
  const [buttons, setButtons] = useState();
  //
  useEffect(()=>{
      if(inputNode !== "empty"){
        //Create Buttons
          var buttonsElements = inputNode.data.buttons.map((button, index) => {
              var buttonText = button.name
              return buttonText;
          });
          var buttonsLinks = inputNode.data.ports.map((link, index) => {
            if(link.target !== null){
              var buttonLink = link.target
              return buttonLink;
            }
          });

          var finalLinks = buttonsLinks.filter(n => n)
          var finalButtons = inputNode.data.buttons.map((button, index) => {
              var element = {name: button.name, link: finalLinks[index], type: "button"}
              return element;
          });
          console.log("Final Buttons", finalButtons)
          setButtons(finalButtons);
      }

  }, [inputNode])




    return (
      <div className={  cn({
          ["ChatInput"]: true,
          ["--dark"]: currentSection === "jofre",
          ["--empty"]: inputNode === "empty"
          })}
          data-scroll data-scroll-sticky data-scroll-target={scrollTarget}
        >
        <div className="ChatInput__container">
          <div className="ChatInput__content">
            <div className="ChatInput__Avatar">
              <Avatar type="user"/>
            </div>
            <div className="ChatInput__Message">
                {buttons && buttons.map((button) =>
                  <div className="ChatInput__Button" key={button.name} onClick={() => handleNewTurn(button)}>
                    {button.name}
                  </div>
                )}
            </div>


          </div>
        </div>


      </div>
    );

}
export default ChatInput;
