
import { useInView } from 'react-intersection-observer';
import React, { useState, useEffect} from "react";
import gsap from 'gsap';
import cn from 'classnames';
import "./Element.scss";


function Element({children, time, threshold, customClass, fixed, inline, delay, once, visibleAction, name} ) {
    const [animate, setAnimate] = useState(false);
    //ANIMATION TIME AND DELAY AS STYLE
    var styleElement;
    var timeElement;
    var delayElement
    if(time){
      timeElement = time;
    }else {
      timeElement = .5;
    }
    if(delay){
      delayElement = delay;
      gsap.delayedCall(delay, setAnimateClass);

    }else {
      delayElement = 0.2;
    }
    styleElement = { transitionDuration:  timeElement+'s', transitionDelay: delayElement+'s' }

    function setAnimateClass() {
      //console.log("animate!");
      setAnimate(true);
    }

    //THRESHOLD
    var thresholdElement
    if(threshold){
      thresholdElement = threshold
    }else {
      thresholdElement = 0.1
    }

    //ONCE ELEMENT
    var onceElement
    if(once){
      onceElement = true
    }else {
      onceElement = false
    }

    const [ref, inView] = useInView({
      threshold: thresholdElement,
      triggerOnce: onceElement,
      trackVisibility: true,
      delay: delayElement*1000
    });

    //CUSTOM CLASS AND OTHER CLASSES
    var customClassElement;
    if(customClass){
      customClassElement = customClass + " " + (fixed ? " --fixed" : "") + (inline ? " --inline" : "");
    }else {
      customClassElement = " " + (fixed ? " --fixed" : "") + (inline ? " --inline" : "");;
    }


    useEffect(() => {
      if(visibleAction){
        visibleAction({inView: inView, name: name});
      }
    }, [inView]);







    return (
      <div
          className={  cn({
            ["Element"]: true,
            [customClassElement]: true,
            ["--visible"]: inView === true,
            ["--animate"]: animate === true && inView === true,
          })}

          ref={ref}
          style={ inView ? styleElement : {}}
        >
        {children}
      </div>
    );

}
export default Element;
