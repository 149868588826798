import "./TextMessage.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import AppContext from 'contexts/AppContext';
import TurnContext from 'contexts/TurnContext';
import Element from 'components/Element/Element';
import cn from 'classnames';
import gsap from 'gsap';

function TextMessage({node, index, handleEvent, totalMessages, moveScroll}) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  const {  messageTurn, setMessageTurn } = useContext(TurnContext);
  const [text, setText] = useState();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const messageText = node.data.texts[0].content[0].children.map(text => text.text);
    setText(messageText.join(''))
  }, [node]);

  useEffect(() => {

      if(messageTurn === index){
        console.log("UN MENSAJE ESTA ACTIVO!", index);
        setIsActive(true);
      }
      if(messageTurn === -1){
        gsap.delayedCall(1, startTurn);
      }

  }, [messageTurn]);

  useEffect(()=>{

    if(isActive === true){
      moveScroll();
      gsap.delayedCall(2, nextTurn);
    }
  }, [isActive])

  function nextTurn(){
    setMessageTurn(index+1);
  }
  function startTurn(){
    setMessageTurn(0);
  }




    return (
      <div className={  cn({
          ["TextMessage"]: true,
          ["--dark"]: currentSection === "jofre",
          ["--active"]: isActive === true,
          ["--first"]: index === 0,
          ["--last"]: index === totalMessages,
          ["--passed"]: messageTurn > index,
          })}
        >
         <Element customClass="TextMessage__Element" visibleAction={(e) => handleEvent(e)} name={"first-element" + text}>
           <div className="TextMessage__Bubble">
              <div className="Bubble__container">
                {text}
              </div>
            </div>
         </Element>

      </div>
    );

}
export default TextMessage;
