import "./Turn.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import Button from 'components/Button/Button'
import Avatar from 'components/Avatar/Avatar'
import AppContext from 'contexts/AppContext';
import TurnContext from 'contexts/TurnContext';
import ChatContext from 'contexts/ChatContext';
import TextMessage from 'components/TextMessage/TextMessage'
import VisualMessage from 'components/VisualMessage/VisualMessage'
import ButtonMessage from 'components/ButtonMessage/ButtonMessage'
import cn from 'classnames';

function Turn({turnNumber, turnType, nodes, blocks, handleEvent, handleNewTurn}) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  const { inputNode, setInputNode, scroll } = useContext(ChatContext);
  const [block, setBlock] = useState();
  const [blockTurns, setBlockTurns] = useState();
  const [messageTurn, setMessageTurn] = useState(-1);
  const [messages, setMessages] = useState();
  const [totalMessages, setTotalMessages] = useState();
  const [turnStatus, setTurnStatus] = useState("running");

  useEffect(() => {
    //If turn is a bot turn
  //  if(turnType === "bot"){
      console.log("blocks desde el turn",blocks, nodes)
      //Assign the block to the turn
      var turnBlock = blocks[turnNumber]
      setBlock(turnBlock);
    //}
  }, [blocks]);

  useEffect(() => {
    //Find the nodes that correspond to this block (turn)
    if(block){

      var turnsForBlock = block.data.steps.map((step, index) => {
          var node = nodes.find(node => node.nodeID === step);
          return node;
      });
      setBlockTurns(turnsForBlock);
      console.log("turnsForBlock", turnsForBlock);
    }
  }, [block]);

  useEffect(() => {
    //Create messages
    if(blockTurns){

      const messagesText = blockTurns.map((message, index) => {
        if(message.type === "text")
            return (
              <TextMessage moveScroll={moveScroll} handleEvent={handleEvent} key={message.nodeID} node={message} index={index} totalMessages={totalMessages-1} />
            )
        if(message.type === "buttons")
            return (
              <ButtonMessage  handleEvent={handleEvent} key={message.nodeID} node={message} index={index} setNode={(node)=> changeInputNode(node)}  />
            )
        if(message.type === "visual")
            return (
              <VisualMessage moveScroll={moveScroll} handleEvent={handleEvent} key={message.nodeID} node={message} index={index}  />
            )
        return <div key={message.nodeID}>Otro tipo {message.type}</div>
      });
      //SET STATE HERE
      setMessages(messagesText);
      setTotalMessages(blockTurns.length)
    }
  }, [blockTurns]);


  function changeInputNode(node){
    setInputNode(node)
  }

  useEffect(() => {
    if(messageTurn === totalMessages){
      console.log("TURNO TERMINADO");
      setTurnStatus("ended");
      if(blockTurns[totalMessages-1].type !== "buttons"){
          console.log("CAMBIAR DE TURNO AUTOMATICAMENTE", blockTurns[totalMessages-1].data.ports[0].target);
          handleNewTurn({name: "automatic-new-turn", link: blockTurns[totalMessages-1].data.ports[0].target })
      }
    }
    if(messageTurn > 0){

    }

  }, [messageTurn]);

  function moveScroll(){
    scroll.scrollTo("bottom");
  }




    return (
      <div className={  cn({
          ["Turn"]: true,
          ["--dark"]: currentSection === "jofre"
          })}
        >
        <div className="Turn__Avatar">
          <Avatar type="bot"/>
        </div>
        <TurnContext.Provider value={{
              messageTurn: messageTurn,
              setMessageTurn: setMessageTurn,

           }}>
          <div className="Turn__Messages">
            {messages}
          </div>
        </TurnContext.Provider>


      </div>
    );

}
export default Turn;
