import "./Chat.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { NavLink} from 'react-router-dom'
import Button from 'components/Button/Button'
import Element from 'components/Element/Element'
import Turn from 'components/Turn/Turn'
import UserTurn from 'components/UserTurn/UserTurn'
import AppContext from 'contexts/AppContext';
import cn from 'classnames';

function Chat({scroll, handleEvent, handleNewTurn, turns, nodes, blocks}) {
  const {  currentSection, setCurrentSection } = useContext(AppContext);
  const [turnsMap, setTurnsMap] = useState();

  useEffect(() => {

    if(turns){
      const listOfTurns = turns.map((turn) => {
        if(turn.type === "bot")
            return (
              <Turn key={turn.index.toString()} turnNumber={turn.index} turnType={turn.type} nodes={nodes} blocks={blocks} handleEvent={handleEvent} handleNewTurn={handleNewTurn} />
            )
        if(turn.type === "user")
            return (
              <UserTurn key={turn.text} handleNewTurn={handleNewTurn} turnData={turn} />
            )
        }
      );
      setTurnsMap(listOfTurns);
    }
  }, [turns]);




    return (
      <div className={  cn({
          ["Chat"]: true,
          ["--dark"]: currentSection === "jofre"
        })}>

        {turnsMap}


      </div>
    );

}
export default Chat;
//
// <Element visibleAction={(e) => handleEvent(e)} name="first-element">
//   <p >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore hic nobis magnam incidunt quos, odit beatae delectus cupiditate nostrum architecto provident ea, odio nesciunt voluptatem harum voluptatum obcaecati excepturi sint!</p>
// </Element>
